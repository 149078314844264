import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DataGrid } from '@mui/x-data-grid';
import PageWrapper from '../../layout/PageWrapper';
import Nav from '../../components/Nav';
import { useAuth } from '../../components/Authentication/AuthProvider';
import Spinner from '../../components/Spinner';
import HeaderBar from '../../components/HeaderBar';
import IconButton from '../../components/IconButton';
import Modal from '../../components/Modal';
import Dropzone from '../../components/Dropzone';
import Alert from '../../components/Alert';
import CoefficientDetails from '../../components/Coefficients/CoefficientDetails';
import { COLORS } from '../../utils/constants/colors';
import {
  getAllCoefficients,
  getCoefficient,
  updateCoefficients,
  deleteCoefficient,
} from '../../api/coefficientsAPI';
import InputField from '../../components/InputField';

const DropzoneGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const CenteredActions = styled.div`
  padding-left: 1rem;
`;

const Coefficients = () => {
  const [coefficients, setCoefficients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [coefficientDetail, setCoefficientDetail] = useState({});
  const [selectedCoefficient, setSelectedCoefficient] = useState(null);
  const [selectedRows, setSelectedRows] = useState(false);
  const [newCoefficients, setNewCoefficients] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState('');
  const { token } = useAuth();

  //MODAL VISIBILITY
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleCloseDetail = () => {
    setIsDetailOpen(false);
  };

  const handleChangeNotificationMessage = (e) => {
    setNotificationMessage(e.target.value);
  };

  // GET ALL COEFFICIENTS
  const handleGetAllCoefficients = useCallback(async () => {
    setIsLoading(true);
    const arrayCoefficients = await getAllCoefficients(token);
    setCoefficients(arrayCoefficients);
    setIsLoading(false);
  }, [token]);

  useEffect(() => {
    handleGetAllCoefficients();
  }, [handleGetAllCoefficients]);

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  }, [errorMessage]);

  // GET COEFFICIENT
  const handleGetCoefficient = useCallback(
    async (id) => {
      setIsLoading(true);
      setIsDetailOpen(true);
      const data = await getCoefficient(token, id);
      setCoefficientDetail(data);
      setIsLoading(false);
    },
    [token]
  );

  // UPDATE COEFFICIENT
  const handleUpdateCoefficient = async () => {
    setIsLoading(true);

    for (let newCoefficient of newCoefficients) {
      const { BATCH_ID, coefficientVector } = newCoefficient;
      const response = await updateCoefficients(
        BATCH_ID,
        coefficientVector,
        token,
        notificationMessage
      );

      if (response.status === 200) {
        handleCloseModal();
        handleGetAllCoefficients();
        setIsLoading(false);
      } else {
        setErrorMessage('Coefficient version is too old.');
        setIsLoading(false);
      }
    }
  };

  // DELETE COEFFICIENT
  const handleDeleteCoefficient = async (id) => {
    setIsLoading(true);
    await deleteCoefficient(id, token);
    handleGetAllCoefficients();
    setIsLoading(false);
  };

  const handleConfirmDelete = () => {
    handleDeleteCoefficient(selectedCoefficient);
    setIsConfirmationOpen(false);
  };

  // DELETE MULTIPLE COEFFICIENTS
  const handleDeleteMultipleCoefficients = async () => {
    setIsLoading(true);
    for (let selectedRow of selectedRows) {
      await handleDeleteCoefficient(selectedRow);
    }
    handleGetAllCoefficients();
    setIsLoading(false);
  };

  const handleDeleteMultiple = () => {
    setIsConfirmationOpen(true);
  };

  const handleConfirmDeleteMultiple = () => {
    handleDeleteMultipleCoefficients();
    setIsConfirmationOpen(false);
  };

  // TABLE
  const columns = [
    {
      field: 'id',
      headerName: 'Batch ID',
      minWidth: 80,
      maxWidth: 80,
      flex: 1,
    },
    {
      field: 'version',
      headerName: 'Version',
      minWidth: 80,
      maxWidth: 100,
      flex: 1,
    },
    {
      field: 'uploadTime',
      headerName: 'Upload Time',
      minWidth: 200,
      maxWidth: 200,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 100,
      maxWidth: 100,
      flex: 1,
      renderCell: (rowData) => (
        <DeleteButton rowData={rowData}>Delete</DeleteButton>
      ),
    },
  ];

  // TABLE ACTIONS
  const DeleteButton = ({ rowData }) => {
    const handleDelete = () => {
      setIsConfirmationOpen(true);
      setSelectedCoefficient(rowData?.id);
    };

    return !selectedRows.length ? (
      <CenteredActions>
        <IconButton onClick={handleDelete}>
          <DeleteOutlineIcon
            style={{ height: '20px', color: `${COLORS.red}` }}
          />
        </IconButton>
      </CenteredActions>
    ) : null;
  };

  return (
    <>
      <Nav />
      <PageWrapper>
        <HeaderBar
          title='Coefficients'
          onOpenModal={handleOpenModal}
          handleDeleteMultiple={handleDeleteMultiple}
          disableDelete={selectedRows.length}
        />
        <div style={{ height: '70vh', width: '100%' }}>
          <DataGrid
            rows={coefficients || []}
            columns={columns}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(rowsID) => {
              setSelectedRows(rowsID);
            }}
            onCellClick={(e) => {
              if (e.field !== 'actions' && e.field !== '__check__') {
                handleGetCoefficient(e?.id);
              }
            }}
            sx={{ color: `${COLORS.darkGray}` }}
          />
        </div>
      </PageWrapper>
      {isLoading && <Spinner />}
      <Modal
        title='Upload coefficient'
        isModalOpen={isModalOpen}
        onOpenModal={handleOpenModal}
        onCloseModal={handleCloseModal}
        onSave={handleUpdateCoefficient}
        disableSave={!newCoefficients.length || !notificationMessage}
      >
        <DropzoneGrid>
          <Dropzone
            setNewCoefficients={setNewCoefficients}
            fileExtension={['.json']}
          />
          <InputField
            label='Notification message:'
            value={notificationMessage}
            onChange={handleChangeNotificationMessage}
            placeholder='Example: New Coefficient Uploaded'
            required
          />
        </DropzoneGrid>
        {errorMessage && (
          <div style={{ marginTop: '2rem' }}>
            <Alert error>{errorMessage}</Alert>
          </div>
        )}
      </Modal>
      <Modal
        title='Confirmation'
        isModalOpen={isConfirmationOpen}
        saveText={selectedRows ? 'Delete Multiple' : 'Delete'}
        onSave={
          selectedRows.length
            ? handleConfirmDeleteMultiple
            : handleConfirmDelete
        }
        onCloseModal={handleCloseConfirmation}
        disableSave={false}
      >
        {selectedRows.length
          ? 'Are you sure you want to delete these coefficients?'
          : 'Are you sure you want to delete this coefficient?'}
      </Modal>
      <Modal
        title='Coefficient details'
        isModalOpen={isDetailOpen}
        onCloseModal={handleCloseDetail}
        closeText='Close'
        width='90vw'
        maxWidth='85rem'
        height='90vh'
        padding='2rem'
        noSaveButton
        noFooter
      >
        <CoefficientDetails data={coefficientDetail} />
      </Modal>
    </>
  );
};

export default Coefficients;
