import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components';
import moment from 'moment';
import { COLORS } from '../../utils/constants/colors';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 10rem 0.1px 40rem;
  gap: 0.5rem;

  @media (max-width: 835px) {
    grid-template-columns: 10rem 0.1px 30rem;
  }
  @media (max-width: 700px) {
    grid-template-columns: 30rem;
    gap: 1rem;
  }
`;

const Table = styled.div`
  min-height: 60vh;
  width: auto;
  // margin-bottom: 1rem;
  overflow: scroll;

  @media (max-width: 700px) {
    min-height: 35vh;
  }
`;

const StyledTitle = styled.h1`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: ${COLORS.darkGray};
  padding-bottom: 0.5rem;
`;

const StyledDivider = styled.div`
  @media (max-width: 700px) {
    border-top: solid 1px ${COLORS.lighterGray};
  }
`;

const UserResults = ({ data }) => {
  const [assayConfigs, setAssayConfigs] = useState([]);
  const [testResults, setTestResults] = useState([]);

  const assayConfigsWithID = data?.assay_configs?.map((assayConfig) => {
    return {
      ...assayConfig,
      id: assayConfig.lot_id,
    };
  });

  const formattedTestResults = data?.test_results?.map((testResult) => {
    return {
      ...testResult,
      date: moment(testResult.date).format('DD/MM/YYYY'),
    };
  });

  useEffect(() => {
    setAssayConfigs(assayConfigsWithID);
    setTestResults(formattedTestResults);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const assayTable = [
    {
      field: 'lot_id',
      headerName: 'Lot ID',
      minWidth: 80,
      flex: 1,
    },
    {
      field: 'version',
      headerName: 'Version',
      minWidth: 80,
      flex: 1,
    },
  ];

  const testResultsTable = [
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 100,
      maxWidth: 100,
      flex: 1,
    },
    {
      field: 'firmware_version',
      headerName: 'Firmware Version',
      minWidth: 150,
      maxWidth: 150,
      flex: 1,
    },
    {
      field: 'value',
      headerName: 'Value',
      minWidth: 70,
      maxWidth: 70,
      flex: 1,
    },
    {
      field: 'notes',
      headerName: 'Notes',
      minWidth: 130,
      flex: 1,
    },
  ];

  return (
    <Grid>
      <Table>
        <StyledTitle>Assay Configs</StyledTitle>
        <DataGrid
          rows={assayConfigs || []}
          columns={assayTable}
          disableSelectionOnClick
          hideFooterPagination
          hideFooter
          density='compact'
          sx={{ color: `${COLORS.darkGray}` }}
        />
      </Table>
      <StyledDivider />
      <Table>
        <StyledTitle>Test Results</StyledTitle>
        <DataGrid
          rows={testResults || []}
          columns={testResultsTable}
          disableSelectionOnClick
          hideFooterPagination
          hideFooter
          density='compact'
          sx={{ color: `${COLORS.darkGray}` }}
        />
      </Table>
    </Grid>
  );
};

export default UserResults;
