import styled from 'styled-components';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '../components/Button';
import { COLORS } from '../utils/constants/colors';

const CenteredButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -0.5rem;
  transform: translateY(-0.5rem);
  @media (max-width: 1025px) {
    transform: translateY(-0.2rem);
    margin-top: 1rem;
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StyledButton = styled(Button)`
  background-color: ${COLORS.green};
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const StyledTitle = styled.h1`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${COLORS.darkGray};
`;

const CustomButtonGroup = ({
  assayNumber,
  handleChangeAssay,
  disabled,
  prevDisabled,
  nextDisabled,
}) => {
  return (
    <CenteredButtonGroup>
      <StyledButtonGroup>
        <StyledButton
          onClick={() => handleChangeAssay('previous')}
          disabled={disabled || prevDisabled}
        >
          <ArrowBackIosIcon sx={{ transform: 'translateX(6px)' }} />
        </StyledButton>
        <div>
          <StyledTitle>Assay Number ({assayNumber})</StyledTitle>
        </div>
        <StyledButton
          onClick={() => handleChangeAssay('next')}
          disabled={disabled || nextDisabled}
        >
          <ArrowForwardIosIcon />
        </StyledButton>
      </StyledButtonGroup>
    </CenteredButtonGroup>
  );
};
export default CustomButtonGroup;
