import { useState, useEffect } from 'react';

export const useSessionStorage = (key = 'email', defaultValue = '') => {
  const [value, setValue] = useState(() => {
    const storedValue = sessionStorage.getItem(key);

    try {
      return JSON.parse(storedValue);
    } catch (e) {
      return defaultValue;
    }
  });

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
