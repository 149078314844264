import { useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import Container from '../../layout/Container';
import { useAuth } from '../../components/Authentication/AuthProvider';
import InputField from '../../components/InputField';
import Button from '../../components/Button';
import Link from '../../components/Link';
import Alert from '../../components/Alert';
import Spinner from '../../components/Spinner';
import MainContainer from '../../layout/MainContainer';
import { createToken } from '../../api/authenticationAPI';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { COLORS } from '../../utils/constants/colors';

const Image = styled.img`
  height: 130px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 15rem;
`;

const Title = styled.h1`
  color: ${COLORS.darkGray};
  font-size: 1.2rem;
  font-weight: 500;
`;

const LoginPage = () => {
  const [email, setEmail] = useSessionStorage('email', '');
  const [token, setToken] = useSessionStorage('token', '');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [error, setError] = useState({ email: '', password: '' });
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const from = location.state?.from?.pathname || '/';

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const response = await createToken(email, password);
    const userToken = response?.data?.data?.token;
    const userID = response?.data?.data?.user?.id;
    const isUserAdmin = response?.data?.data?.user?.role === 'administrator';
    setToken(userToken);

    if (response.status === 200) {
      if (isUserAdmin) {
        if (userToken) {
          auth.signIn(email, userToken || '', userID);
          navigate(from, { replace: true });
        } else {
          setErrorMessage('Invalid email or password');
        }
      } else {
        setErrorMessage('You have to be an administrator to login.');
      }
    } else {
      setErrorMessage('Something went wrong. Please try again.');
    }
    setIsLoading(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setErrorMessage('');
    if (name === 'email') {
      setError({ ...error, email: '' });
      setEmail(value);
    } else if (name === 'password') {
      setError({ ...error, password: '' });
      setPassword(value);
      if (password.length >= 5) {
        setLoginDisabled(false);
      } else {
        setLoginDisabled(true);
      }
    }
  };

  const handleBlur = (event) => {
    const { name } = event.target;
    if (name === 'email') {
      if (email === '') {
        setError({ ...error, email: 'E-mail is required' });
      } else if (!email?.includes('@') || !email?.includes('.')) {
        setError({ ...error, email: 'E-mail is invalid' });
      } else {
        setError({ email: '' });
      }
    }
    if (name === 'password') {
      if (password === '') {
        setError({ ...error, password: 'Password is required' });
      } else if (password.length < 6) {
        setError({
          ...error,
          password: 'Password must be at least 6 characters',
        });
      } else {
        setError({ ...error, password: '' });
      }
    }

    if (email && !error.email && password.length > 6 && !error.password) {
      setLoginDisabled(false);
    }
  };

  return (
    <>
      <MainContainer>
        <Container>
          <Image src={Logo} alt="logo" />
          <Form onSubmit={handleSubmit}>
            <Title>Sign in</Title>
            <InputField
              name="email"
              type="email"
              placeholder="E-mail"
              value={email || ''}
              error={error.email}
              onChange={handleChange}
              onBlur={handleBlur}
              autoFocus
            />
            <InputField
              name="password"
              type="password"
              placeholder="Password"
              value={password || ''}
              error={error.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Alert error>{errorMessage}</Alert>
            <Button type="submit" disabled={loginDisabled}>
              Login
            </Button>
          </Form>
          <Link to="/recovery-password" fontSize="0.8rem">
            Forgot password?
          </Link>
        </Container>
      </MainContainer>
      {isLoading && <Spinner />}
    </>
  );
};
export default LoginPage;
