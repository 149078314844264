import styled from 'styled-components';
import { COLORS } from '../utils/constants/colors';

const StyledButton = styled.button`
  background-color: ${COLORS.lighterGray};
  color: ${COLORS.darkGray};
  border: none;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}rem` : 0)};

  &:hover {
    background-color: ${COLORS.lightGray};
  }
`;

const IconButton = ({ children, onClick, mR }) => {
  return (
    <StyledButton onClick={onClick} marginRight={mR}>
      {children}
    </StyledButton>
  );
};

export default IconButton;
