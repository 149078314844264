import { createContext, useContext } from 'react';
import { useSessionStorage } from '../../hooks/useSessionStorage';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useSessionStorage('token', '');
  const [email, setEmail] = useSessionStorage('email', '');
  const [userID, setUserID] = useSessionStorage('id', '');

  const signIn = (email, token, user_id) => {
    setEmail(email);
    setToken(token);
    setUserID(user_id);
  };

  const signOut = () => {
    setEmail(false);
    setToken(false);
    setUserID(false);
  };

  const value = { userID, email, token, signIn, signOut };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
