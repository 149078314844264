import styled from 'styled-components';
import { COLORS } from '../utils/constants/colors';

const StyledPairRow = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1.5fr;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.2rem 0;
  padding-bottom: 0.2rem;
  &:not(:last-child) {
    border-bottom: 1px solid
      ${({ noBorder }) => (noBorder ? 'transparent' : COLORS.lightGray)};
  }
`;

const StyledKey = styled.div`
  margin-right: 1rem;
  white-space: nowrap;
  color: ${COLORS.darkGray};
`;

const StyledValue = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.valueAlignment};
  color: ${COLORS.darkGray};
`;

const KeyValuePair = ({ label, value, valueAlignment = 'right', noBorder }) => {
  return (
    <StyledPairRow noBorder={noBorder}>
      <StyledKey>{label}</StyledKey>
      <StyledValue valueAlignment={valueAlignment}>{value}</StyledValue>
    </StyledPairRow>
  );
};

export default KeyValuePair;
