import moment from 'moment';
import axios from './axios';

// READ
export const getAllCoefficients = async (token) => {
  try {
    const response = await axios.get('/coefficient_vectors', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const formattedData = response?.data?.data.map((coefficient) => {
      return {
        id: coefficient.id,
        version: coefficient.version,
        uploadTime: moment(coefficient.uploadTime).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
      };
    });
    return formattedData;
  } catch (error) {
    return error.response;
  }
};

export const getCoefficient = async (token, id) => {
  try {
    const response = await axios.get(`/coefficient_vector/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    return error.response;
  }
};

//UPDATE
export const updateCoefficients = async (
  BATCH_ID,
  coefficientVector,
  token,
  notificationMessage
) => {
  const newCoefficientVector = {
    config_data: {
      ...coefficientVector,
      LotInformation: {
        ...coefficientVector.LotInformation,
        BarcodeID: BATCH_ID.toString(),
      },
    },
    notification_text: notificationMessage,
  };

  try {
    const response = await axios.put(
      `/coefficient_vector/${BATCH_ID}`,
      newCoefficientVector,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

//DELETE
export const deleteCoefficient = async (id, token) => {
  try {
    await axios.delete(`/coefficient_vector/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    return error.response;
  }
};
