import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DataGrid } from '@mui/x-data-grid';
import PageWrapper from '../../layout/PageWrapper';
import Nav from '../../components/Nav';
import { useAuth } from '../../components/Authentication/AuthProvider';
import UserDetails from '../../components/UserDetails/UserDetails';
import NewUser from '../../components/UserDetails/NewUser';
import UserResults from '../../components/UserDetails/UserResults';
import Spinner from '../../components/Spinner';
import HeaderBar from '../../components/HeaderBar';
import IconButton from '../../components/IconButton';
import Modal from '../../components/Modal';
import { getAllUsers, getUserDetails, deleteUser } from '../../api/usersAPI';
import { COLORS } from '../../utils/constants/colors';
import InputField from '../../components/InputField';
import Alert from '../../components/Alert';

const CenteredActions = styled.div`
  &:not(:first-child) {
    padding-left: 1rem;
  }
`;

const CenteredField = styled.div`
  width: 12.7rem;
  margin-top: 1rem;
  transform: translateX(-0.5rem);
`;

const AlertBox = styled.div`
  margin: 1rem;
`;

const Users = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isUserResultsOpen, setIsUserResultsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRows, setSelectedRows] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [wasUserChanged, setWasUserChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [adminUserWasSelected, setAdminUserWasSelected] = useState(false);
  const { token, userID } = useAuth();

  const filteredUsers = users?.filter((user) => user?.id !== userID);
  const findSelectedUser = users?.find((user) => user?.id === selectedUser);
  const isSelectedUserAdmin = findSelectedUser?.role === 'Administrator';

  //MODAL VISIBILITY
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseConfirmation = () => {
    setErrorMessage('');
    setIsConfirmationOpen(false);
  };

  const handleCloseDetail = () => {
    setIsDetailOpen(false);
  };

  const handleCloseUserResults = () => {
    setIsUserResultsOpen(false);
  };

  // GET ALL USERS
  const handleGetAllUsers = useCallback(async () => {
    setIsLoading(true);
    const arrayUsers = await getAllUsers(token);
    setUsers(arrayUsers);
    setIsLoading(false);
  }, [token]);

  // GET USER
  const handleGetUser = useCallback(
    async (id) => {
      setIsLoading(true);
      const data = await getUserDetails(token, id);
      setUserDetails(data);
      setIsLoading(false);
    },
    [token]
  );

  useEffect(() => {
    handleGetAllUsers();
    setWasUserChanged(false);
  }, [handleGetAllUsers, wasUserChanged]);

  // DELETE USER
  const handleDeleteUser = async (id) => {
    setIsLoading(true);
    const response = await deleteUser(
      token,
      id,
      currentPassword,
      isSelectedUserAdmin,
      adminUserWasSelected
    );
    if (response?.status === 200) {
      setIsConfirmationOpen(false);
    } else {
      setErrorMessage('Authentication with password failed.');
    }
    handleGetAllUsers();
    setIsLoading(false);
  };

  const handleConfirmDelete = (e) => {
    e.preventDefault();
    handleDeleteUser(selectedUser);
  };

  // DELETE MULTIPLE USERS
  const handleDeleteMultipleUsers = async () => {
    setIsLoading(true);
    for (let selectedRow of selectedRows) {
      await handleDeleteUser(selectedRow);
    }
    handleGetAllUsers();
    setIsLoading(false);
  };

  const handleDeleteMultiple = () => {
    setIsConfirmationOpen(true);
  };

  const handleConfirmDeleteMultiple = () => {
    handleDeleteMultipleUsers();
    setIsConfirmationOpen(false);
  };

  // TABLE
  const columns = [
    { field: 'id', headerName: 'ID', minWidth: 50, maxWidth: 50, flex: 1 },
    {
      field: 'username',
      headerName: 'Username',
      minWidth: 200,
      maxWidth: 250,
      flex: 1,
    },
    {
      field: 'role',
      headerName: 'Role',
      minWidth: 150,
      maxWidth: 200,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 200,
      maxWidth: 250,
      flex: 1,
    },
    {
      field: 'fullname',
      headerName: 'Fullname',
      minWidth: 200,
      maxWidth: 250,
      flex: 1,
    },

    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 150,
      maxWidth: 150,

      renderCell: (rowData) => (
        <>
          <ReadButton rowData={rowData}>Read</ReadButton>
          <EditButton rowData={rowData}>Edit</EditButton>
          <DeleteButton rowData={rowData}>Delete</DeleteButton>
        </>
      ),
    },
  ];

  // TABLE ACTIONS
  const ReadButton = ({ rowData }) => {
    const handleRead = () => {
      setIsUserResultsOpen(true);
      handleGetUser(rowData?.id);
    };

    return !selectedRows.length ? (
      <CenteredActions>
        <IconButton onClick={handleRead}>
          <DescriptionOutlinedIcon
            style={{ height: '20px', color: `${COLORS.greenRGBA}` }}
          />
        </IconButton>
      </CenteredActions>
    ) : null;
  };

  const EditButton = ({ rowData }) => {
    const handleEdit = () => {
      setIsDetailOpen(true);
      handleGetUser(rowData?.id);
    };

    return !selectedRows.length ? (
      <CenteredActions>
        <IconButton onClick={handleEdit}>
          <ModeEditOutlineOutlinedIcon
            style={{ height: '20px', color: `${COLORS.gray}` }}
          />
        </IconButton>
      </CenteredActions>
    ) : null;
  };

  const DeleteButton = ({ rowData }) => {
    const handleDelete = () => {
      setIsConfirmationOpen(true);
      setSelectedUser(rowData?.id);
    };

    return !selectedRows.length ? (
      <CenteredActions>
        <IconButton onClick={handleDelete}>
          <DeleteOutlineIcon
            style={{ height: '20px', color: `${COLORS.red}` }}
          />
        </IconButton>
      </CenteredActions>
    ) : null;
  };
  // TABLE ACTIONS END

  return (
    <>
      <Nav />
      <PageWrapper>
        <HeaderBar
          title="User Accounts"
          onOpenModal={handleOpenModal}
          handleDeleteMultiple={handleDeleteMultiple}
          disableDelete={selectedRows.length}
        />
        <div style={{ height: '70vh', width: '100%' }}>
          <DataGrid
            rows={filteredUsers || []}
            columns={columns}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(rowsID) => {
              setSelectedRows(rowsID);
              for (let row of rowsID) {
                const user = filteredUsers?.find((user) => user?.id === row);
                const isUserAdmin = user?.role === 'Administrator';
                if (isUserAdmin) {
                  setAdminUserWasSelected(true);
                } else {
                  setAdminUserWasSelected(false);
                }
              }
            }}
            sx={{ color: `${COLORS.darkGray}` }}
          />
        </div>
      </PageWrapper>
      {isLoading && <Spinner />}
      <Modal
        title="Add new user"
        isModalOpen={isModalOpen}
        onOpenModal={handleOpenModal}
        onCloseModal={handleCloseModal}
        noFooter
      >
        <NewUser />
      </Modal>
      <Modal
        title="Confirmation"
        isModalOpen={isConfirmationOpen}
        saveText={selectedRows ? 'Delete Multiple' : 'Delete'}
        onSave={
          selectedRows.length
            ? handleConfirmDeleteMultiple
            : handleConfirmDelete
        }
        onCloseModal={handleCloseConfirmation}
        disableSave={isSelectedUserAdmin && !currentPassword.length}
      >
        {selectedRows.length
          ? 'Are you sure you want to delete these users?'
          : 'Are you sure you want to delete this user?'}

        {isSelectedUserAdmin ? (
          <>
            <CenteredField>
              <InputField
                label="Administrator password"
                name="current-password"
                placeholder="Administrator password"
                type="password"
                onChange={(e) => {
                  setErrorMessage('');
                  setCurrentPassword(e.target.value);
                }}
              />
            </CenteredField>
            <AlertBox>
              <Alert error>{errorMessage}</Alert>
            </AlertBox>
          </>
        ) : adminUserWasSelected ? (
          <>
            <CenteredField>
              <InputField
                label="Administrator password"
                name="current-password"
                placeholder="Administrator password"
                type="password"
                onChange={(e) => {
                  setErrorMessage('');
                  setCurrentPassword(e.target.value);
                }}
              />
            </CenteredField>
            <AlertBox>
              <Alert error>{errorMessage}</Alert>
            </AlertBox>
          </>
        ) : null}
      </Modal>
      <Modal
        title="User details"
        isModalOpen={isDetailOpen}
        onCloseModal={handleCloseDetail}
        disableSave={false}
        noSaveButton
        noFooter
      >
        <UserDetails data={userDetails} setWasUserChanged={setWasUserChanged} />
      </Modal>
      <Modal
        title="User Test Results"
        isModalOpen={isUserResultsOpen}
        onCloseModal={handleCloseUserResults}
        disableSave={false}
        width="auto"
        noSaveButton
        noFooter
      >
        <UserResults data={userDetails} />
      </Modal>
    </>
  );
};

export default Users;
