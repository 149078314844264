import React, { useState } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { findBatchID, findVersion } from '../utils/helpers/helpers';
import { COLORS } from '../utils/constants/colors';

const DashedArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 1rem;
  border: 3px dashed
    ${({ isFocused }) => (isFocused ? COLORS.green : COLORS.lightGray)};
  height: 10rem;
  border-radius: 0.5rem;
  color: ${COLORS.lightGray};
`;

const StyledIcon = styled(UploadFileIcon)`
  height: 3rem !important;
  width: 3rem !important;
`;

const StyledCheckIcon = styled(CheckCircleIcon)`
  color: ${COLORS.green};
`;

const UploadedFiles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const FileDescription = styled.p`
  font-size: 0.8rem;
  font-weight: bold;
  color: ${COLORS.darkGray};
`;

const FileSize = styled.p`
  font-size: 0.8rem;
  color: ${COLORS.darkGray};
  white-space: nowrap;
`;

const Dropzone = ({ setNewCoefficients, setNewFirmwares, fileExtension }) => {
  const [arrayCoefficients, setArrayCoefficients] = useState([]);
  const [arrayFirmwares, setArrayFirmwares] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps, isFocused } = useDropzone(
    {
      accept: {
        'application/json': fileExtension,
      },
    }
  );

  return (
    <>
      <DashedArea {...getRootProps()} isFocused={isFocused}>
        <input {...getInputProps()} />
        <StyledIcon />
        <p>
          Click or Drag & Drop <br />
          {fileExtension} files here
        </p>
      </DashedArea>
      {acceptedFiles.map((file) => {
        if (fileExtension[0] === '.json') {
          const reader = new FileReader();
          reader.onload = () => {
            const BATCH_ID = findBatchID(reader.result);
            const VERSION = findVersion(reader.result);

            const result = reader.result.replace('"LotID": 000', '"LotID": ');
            const result2 = result.replace('"BarcodeID": 000', '"BarcodeID": ');
            const result3 = result2.replace('"LotID": 00', '"LotID": ');
            const result4 = result3.replace('"BarcodeID": 00', '"BarcodeID": ');
            const result5 = result4.replace('"LotID": 0', '"LotID": ');
            const finalResult = result5.replace(
              '"BarcodeID": 0',
              '"BarcodeID": '
            );

            if (!arrayCoefficients.some((item) => item.BATCH_ID === BATCH_ID)) {
              setArrayCoefficients([
                ...arrayCoefficients,
                {
                  BATCH_ID,
                  VERSION,
                  coefficientVector: JSON.parse(finalResult),
                },
              ]);
            }
          };
          reader.onloadend = () => {
            setNewCoefficients(arrayCoefficients);
          };
          reader.readAsText(file);
        } else {
          const reader = new FileReader();
          reader.onload = () => {
            if (!arrayFirmwares.some((item) => item.path === file.path)) {
              setArrayFirmwares([...arrayFirmwares, file]);
            }
          };
          reader.onloadend = () => {
            setNewFirmwares(arrayFirmwares);
          };
          reader.readAsText(file);
        }

        return (
          <UploadedFiles key={file.name}>
            <StyledCheckIcon />
            <FileDescription>{file.name}</FileDescription>
            <FileSize>{(file.size / 1024).toFixed(2)} KB</FileSize>
          </UploadedFiles>
        );
      })}
    </>
  );
};

export default Dropzone;
