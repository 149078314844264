import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import LoginPage from './pages/Login/LoginPage';
import Dashboard from './pages/Dashboard/Dashboard';
import Page404 from './pages/Page404/Page404';
import RecoveryPassword from './pages/RecoveryPassword/RecoveryPassword';
import ResetPassword from './pages/RecoveryPassword/ResetPassword';
import Coefficients from './pages/Coefficients/Coefficients';
import Firmware from './pages/Firmware/Firmware';
import Users from './pages/Users/Users';
import { RequireAuth } from './components/Authentication/RequireAuth';
import './styles.css';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90rem;
  @media (max-width: 1300px) {
    width: 100%;
  }
`;

const App = () => {
  return (
    <Wrapper>
      <CenteredContent>
        <Routes>
          <Route
            path='/'
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route path='/login' element={<LoginPage />} />
          <Route path='*' element={<Page404 />} />
          <Route path='/recovery-password' element={<RecoveryPassword />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route
            path='/coefficients'
            element={
              <RequireAuth>
                <Coefficients />
              </RequireAuth>
            }
          />
          <Route
            path='/firmwares'
            element={
              <RequireAuth>
                <Firmware />
              </RequireAuth>
            }
          />
          <Route
            path='/users'
            element={
              <RequireAuth>
                <Users />
              </RequireAuth>
            }
          />
        </Routes>
      </CenteredContent>
    </Wrapper>
  );
};

export default App;
