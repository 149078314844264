import { v4 as uuidv4 } from 'uuid';
import axios from './axios';

const uuid = uuidv4();
export const initiatePasswordRecovery = async (email) => {
  try {
    const response = await axios.post('/password_reset/initiate', {
      clientToken: uuid,
      email,
      lang: 'en-GB',
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const completePasswordRecovery = async (serverToken, password) => {
  try {
    const response = await axios.post('/password_reset/complete', {
      clientToken: uuid,
      password,
      serverToken,
    });

    return response;
  } catch (error) {
    return error;
  }
};
