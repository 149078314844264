import { useState } from 'react';
import styled from 'styled-components';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import MainContainer from '../../layout/MainContainer';
import Container from '../../layout/Container';
import InputField from '../../components/InputField';
import Button from '../../components/Button';
import Alert from '../../components/Alert';
import { completePasswordRecovery } from '../../api/passwordRecoveryAPI';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { COLORS } from '../../utils/constants/colors';

const Image = styled.img`
  height: 100px;
`;

const Title = styled.h1`
  color: ${COLORS.darkGray};
  font-size: 1.2rem;
  font-weight: 500;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 15rem;
`;

const StyledPasswordStrengthBar = styled(PasswordStrengthBar)`
  width: 100%;
  height: 1rem;
  transform: translateY(-1rem);
`;

const RecoveryPassword = () => {
  const [email] = useSessionStorage('email', '');
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [message, setMessage] = useState('');
  const [isPasswordReseted, setIsPasswordReseted] = useState(false);
  const [error, setError] = useState({
    token: '',
    password: '',
    passwordConfirmation: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await completePasswordRecovery(token, password);

    if (response?.data?.data?.success) {
      setMessage('Password reseted successfully!');
      setIsPasswordReseted(true);
    } else {
      setMessage('Something went wrong!');
    }
  };

  const navigate = useNavigate();

  const handleBackToLogin = () => {
    navigate('/login');
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    if (name === 'new-password') {
      if (!password) {
        setError({ ...error, password: 'Password is required' });
      } else if (passwordStrength < 1) {
        setError({ ...error, password: 'Please enter a strong password' });
      } else {
        setError({ ...error, password: '' });
      }
    }
    if (name === 'new-password-confirm') {
      if (!passwordConfirmation) {
        setError({ ...error, passwordConfirmation: 'Password is required' });
      } else if (password !== passwordConfirmation) {
        setError({ ...error, passwordConfirmation: 'Passwords do not match' });
        setPasswordConfirmation('');
      } else {
        setError({ ...error, passwordConfirmation: '' });
      }
    }
    if (password === passwordConfirmation && passwordStrength > 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  return (
    <MainContainer>
      <Container>
        <Image src={Logo} alt='logo' />
        <Form
          onSubmit={isPasswordReseted ? handleBackToLogin : handleSubmit}
          autoComplete='off'
        >
          <Title>Reset Password</Title>
          <InputField
            name='email'
            placeholder='E-mail'
            type='email'
            value={email}
            disabled
          />
          <Alert>
            You should receive a token by email. Please copy it from the email
            and paste it here.
          </Alert>
          <InputField
            name='token'
            placeholder='Token'
            type='text'
            value={token}
            onChange={(e) => {
              setError({ ...error, token: '' });
              setToken(e.target.value);
            }}
            onBlur={handleBlur}
            error={token.password}
            disabled={isPasswordReseted}
            autoFocus
          />
          <InputField
            name='new-password'
            placeholder='New Password'
            type='password'
            value={password}
            onChange={(e) => {
              setError({ ...error, password: '' });
              setPassword(e.target.value);
            }}
            onBlur={handleBlur}
            error={error.password}
            disabled={isPasswordReseted}
          />

          <InputField
            name='new-password-confirm'
            placeholder='New Password confirm'
            type='password'
            value={passwordConfirmation}
            onChange={(e) => {
              setError({ ...error, passwordConfirmation: '' });
              setPasswordConfirmation(e.target.value);
            }}
            onBlur={handleBlur}
            error={error.passwordConfirmation}
            disabled={isPasswordReseted}
          />
          {!isPasswordReseted && (
            <StyledPasswordStrengthBar
              password={password}
              barColors={[
                COLORS.gray,
                COLORS.red,
                COLORS.orange,
                COLORS.green,
                COLORS.green,
                COLORS.green,
              ]}
              onChangeScore={(score) => {
                setPasswordStrength(score > 1);
              }}
            />
          )}

          {isPasswordReseted ? (
            <Button type='submit'>Back to login</Button>
          ) : (
            <Button type='submit' disabled={!isFormValid}>
              Reset Password
            </Button>
          )}

          {isPasswordReseted ? (
            <Alert success>{message}</Alert>
          ) : (
            <Alert error>{message}</Alert>
          )}
        </Form>
      </Container>
    </MainContainer>
  );
};
export default RecoveryPassword;
