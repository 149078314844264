import styled from 'styled-components';
import { COLORS } from '../utils/constants/colors';

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  padding: 0.5rem 0 0.5rem 0.5rem;
`;

const Label = styled.label`
  color: ${COLORS.darkGray};
  font-size: 0.8rem;
  font-weight: bold;
`;

const StyledSelect = styled.select`
  border: 1px solid ${COLORS.lightGray};
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100%;
  color: ${COLORS.darkGray};
  background-color: ${({ disabled }) =>
    disabled ? COLORS.lightGray : COLORS.white};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
`;

const Select = ({
  onSelectChange,
  isEditingUser,
  label,
  value,
  emptySelection,
}) => {
  const formattedValue = value?.replace(' ', '-');

  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <StyledSelect
        onChange={onSelectChange}
        value={formattedValue}
        disabled={!isEditingUser}
      >
        {emptySelection && <option value="none">Select Role</option>}
        <option value="administrator">Administrator</option>
        <option value="blood-tester">Blood Tester</option>
      </StyledSelect>
    </FormGroup>
  );
};

export default Select;
