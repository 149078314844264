import styled from 'styled-components';
import { COLORS } from '../utils/constants/colors';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: ${({ outlined }) =>
    outlined ? 'transparent' : COLORS.darkGray};
  color: ${({ outlined }) => (outlined ? COLORS.darkGray : COLORS.white)};
  border: ${({ outlined }) =>
    outlined ? `0.2px solid ${COLORS.darkGray}` : 'none'};
  text-decoration: none;
  padding: ${({ small }) => (small ? '0.2rem 0.5rem' : '0.5rem 1rem')};
  border-radius: 0.5rem;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  white-space: nowrap;

  &:active {
    background-color: ${COLORS.black};
  }
`;

const StyledIcon = styled.span`
  height: 1.5rem;
  width: 1.5rem;
`;

const Button = ({
  children,
  onClick,
  disabled,
  type = 'button',
  icon,
  small = false,
  outlined,
  ...props
}) => {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      type={type}
      small={small}
      outlined={outlined}
      {...props}
    >
      {children}
      {icon && <StyledIcon>{icon}</StyledIcon>}
    </StyledButton>
  );
};

export default Button;
