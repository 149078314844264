import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import Button from './Button';
import IconButton from './IconButton';
import { COLORS } from '../utils/constants/colors';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background-color: ${COLORS.white};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  width: ${({ width }) => width || '30rem'};
  height: ${({ height }) => height || 'auto'};
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: scroll;
`;

const ModalHeader = styled.div`
  padding: ${({ padding }) => padding || '1.5rem 1rem'};
  padding-bottom: 1rem;
  border-bottom: 1px solid ${COLORS.lighterGray};
  display: flex;
  justify-content: space-between;
`;

const ModalTitle = styled.h1`
  color: ${COLORS.darkGray};
  font-size: 1.2rem;
  font-weight: bold;
  flex: 1;
`;

const ModalCloseButton = styled(IconButton)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0rem 1rem;
  cursor: pointer;
`;

const ModalContent = styled.div`
  padding: 1rem;
  flex: 1;
`;

const ModalFooter = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: flex-end;
  border-top: 1px solid ${COLORS.lighterGray};
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

const Modal = ({
  children,
  onCloseModal,
  onSave,
  isModalOpen,
  title,
  saveText,
  noSaveButton,
  noFooter,
  width,
  height,
  padding,
  disableSave = true,
  maxWidth,
}) => {
  const handleCloseOverlayClick = (e) => {
    if (e.target.id === 'modalOverlay') {
      onCloseModal();
    }
  };
  return (
    isModalOpen && (
      <ModalOverlay onClick={handleCloseOverlayClick} id='modalOverlay'>
        <ModalContainer width={width} height={height} maxWidth={maxWidth}>
          <ModalHeader padding={padding}>
            <ModalTitle>{title}</ModalTitle>
            <ModalCloseButton onClick={onCloseModal}>
              <CloseIcon sx={{ color: `${COLORS.darkGray}` }} />
            </ModalCloseButton>
          </ModalHeader>
          <ModalContent>{children}</ModalContent>
          {!noFooter && (
            <ModalFooter>
              <ModalButtons>
                {!noSaveButton && (
                  <Button onClick={onSave} disabled={disableSave}>
                    {saveText ? saveText : 'Upload file'}
                  </Button>
                )}
              </ModalButtons>
            </ModalFooter>
          )}
        </ModalContainer>
      </ModalOverlay>
    )
  );
};

export default Modal;
