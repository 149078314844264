import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components';
import KeyValuePair from '../KeyValuePair';
import ButtonGroup from '../ButtonGroup';
import { COLORS } from '../../utils/constants/colors';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  padding: 0 1rem;
  @media (max-width: 1025px) {
    grid-template-columns: 1fr;
  }
`;

const StyledTitle = styled.h1`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: ${COLORS.darkGray};
  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || COLORS.lighterGray};
  border-radius: 0.5rem;
  padding: 1.5rem 1rem;
`;

const StyledLeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: 1025px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const GridGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CoefficientDetails = ({ data }) => {
  const [currentAssay, setCurrentAssay] = useState(0);
  const [assaysArray, setAssaysArray] = useState([]);

  const handleChangeAssay = (action) => {
    const min = 0;
    const max = assaysArray.length - 1;
    if (action === 'next' && currentAssay < max) {
      setCurrentAssay(currentAssay + 1);
    }
    if (action === 'previous' && currentAssay > min) {
      setCurrentAssay(currentAssay - 1);
    }
  };

  const lotInformation = data?.assayConfig?.LotInformation;
  const controlRoiConfig = data?.assayConfig?.ControlRoiConfig;
  const correctionFactorScalar = data?.assayConfig?.CorrectionFactorScalar;
  const ledBrightnessScalar = data?.assayConfig?.LedBrightnessScalar;

  const detailColumns = [
    { field: 'TableIndex', headerName: 'Index', flex: 1 },
    { field: 'Signal', headerName: 'Signal', flex: 1 },
    {
      field: 'Concentration',
      headerName: 'Concentration',
      minWidth: 130,
      flex: 1,
    },
  ];

  const assayArray = data?.assayConfig?.Assays;

  useEffect(() => {
    setAssaysArray(assayArray);
  }, [data, assayArray]);

  const assayCurvesLookup =
    data?.assayConfig?.Assays[currentAssay]?.CurveLookupTable;
  const arrayCurvesWithId = assayCurvesLookup?.map((curve) => {
    return {
      ...curve,
      id: curve.TableIndex,
    };
  });
  const assayIdentifier = data?.assayConfig?.Assays[currentAssay]?.Identifier;
  const assayROIConfig = data?.assayConfig?.Assays[currentAssay]?.RoiConfig;
  const assayUnitsInformation =
    data?.assayConfig?.Assays[currentAssay]?.UnitsInformation;

  return (
    <Grid>
      <GridGap>
        <StyledTitle>Coefficient Information</StyledTitle>
        <StyledLeftPanel>
          <StyledList backgroundColor={COLORS.lightGray}>
            <StyledTitle>Lot information</StyledTitle>
            <KeyValuePair label='Lot ID: ' value={lotInformation?.LotID} />
            <KeyValuePair
              label='Barcode ID: '
              value={lotInformation?.BarcodeID}
            />
            <KeyValuePair
              label='Test Date: '
              value={lotInformation?.TestDate}
            />
            <KeyValuePair
              label='Number of assays: '
              value={lotInformation?.NumberOfAssays}
            />
            <KeyValuePair label='Version: ' value={lotInformation?.Version} />
          </StyledList>
          <StyledList backgroundColor={COLORS.lightGray}>
            <StyledTitle>Control Roi Config</StyledTitle>
            <KeyValuePair
              label='Baseline ROI Offset: '
              value={controlRoiConfig?.BaselineROIOffset}
            />
            <KeyValuePair
              label='Baseline ROI Width: '
              value={controlRoiConfig?.BaselineROIWidth}
            />
            <KeyValuePair
              label='Peak ROI Width: '
              value={controlRoiConfig?.PeakROIWidth}
            />
            <KeyValuePair
              label='Scan Location Offset: '
              value={controlRoiConfig?.ScanLocationOffset}
            />
            <KeyValuePair
              label='Scan Window Size: '
              value={controlRoiConfig?.ScanWindowSize}
            />
          </StyledList>
          <StyledList backgroundColor={COLORS.lightGray}>
            <StyledTitle>Correction Factor Scalar</StyledTitle>
            <KeyValuePair label={correctionFactorScalar?.join(',')} />
          </StyledList>
          <StyledList backgroundColor={COLORS.lightGray}>
            <StyledTitle>Led Brightness Scalar</StyledTitle>
            <KeyValuePair label={ledBrightnessScalar?.join(',')} />
          </StyledList>
        </StyledLeftPanel>
      </GridGap>
      <GridGap>
        <ButtonGroup
          assayNumber={assayIdentifier?.Number}
          handleChangeAssay={handleChangeAssay}
          disabled={assaysArray?.length === 1}
          prevDisabled={currentAssay === 0}
          nextDisabled={currentAssay === assaysArray?.length - 1}
        />
        <StyledLeftPanel>
          <StyledList>
            <StyledTitle>Identifier</StyledTitle>
            <KeyValuePair label='Number: ' value={assayIdentifier?.Number} />
            <KeyValuePair label='Name: ' value={assayIdentifier?.Name} />
            <KeyValuePair
              label='Duration: '
              value={assayIdentifier?.Duration}
            />
          </StyledList>
          <StyledList>
            <StyledTitle>ROI Config</StyledTitle>
            <KeyValuePair
              label='Baseline ROI Offset '
              value={assayROIConfig?.BaselineROIOffset}
            />
            <KeyValuePair
              label='Baseline ROI Width '
              value={assayROIConfig?.BaselineROIWidth}
            />
            <KeyValuePair
              label='Peak ROI Width '
              value={assayROIConfig?.PeakROIWidth}
            />
            <KeyValuePair
              label='Scan Location Offset '
              value={assayROIConfig?.ScanLocationOffset}
            />
            <KeyValuePair
              label='Scan Window Size '
              value={assayROIConfig?.ScanWindowSize}
            />
          </StyledList>
          <StyledList>
            <StyledTitle>Units Information</StyledTitle>
            <KeyValuePair
              label='Colors: '
              value={assayUnitsInformation?.Colors}
            />
            <KeyValuePair label='High: ' value={assayUnitsInformation?.High} />
            <KeyValuePair label='Low: ' value={assayUnitsInformation?.Low} />
            <KeyValuePair
              label='Results Formatter: '
              value={assayUnitsInformation?.ResultsFormatter}
            />
            <KeyValuePair
              label='Units: '
              value={assayUnitsInformation?.Units}
            />
          </StyledList>
        </StyledLeftPanel>
      </GridGap>
      <GridGap>
        <StyledTitle>Curve Lookup Table</StyledTitle>
        <DataGrid
          rows={arrayCurvesWithId || []}
          columns={detailColumns}
          density='compact'
          disableSelectionOnClick
          hideFooterPagination
          autoHeight
          sx={{
            marginTop: '0.2rem',
            color: `${COLORS.darkGray}`,
          }}
        />
      </GridGap>
    </Grid>
  );
};

export default CoefficientDetails;
