export const COLORS = {
  red: '#F95F62',
  green: '#53D388',
  greenRGBA: 'rgba(63, 174, 42, 0.7)',
  orange: '#FE7701',
  lightOrange: '#FE9400',
  ligherOrange: '#FFBA5C',
  darkBlue: '#054D58',
  black: '#1C2833',
  darkGray: '#323F49',
  gray: '#8C9EA6',
  lightGray: '#D3D3D3',
  lighterGray: '#F5F5F5',
  white: '#FFFFFF',
};
