import axios from 'axios';

// For now we have 3 servers:
// https://1.stb.cust.purpledecks.com/api - OLD DEV SERVER
// https://server.sidekickanimalhealth.com/api. LIVE / PRODUCTION SERVER
// https://devserver.sidekickanimalhealth.com/api. NEW DEV SERVER

const axiosInstance = axios.create({
  baseURL: 'https://server.sidekickanimalhealth.com/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
