import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PageWrapper from '../../layout/PageWrapper';
import Container from '../../layout/Container';
import Nav from '../../components/Nav';
import { useAuth } from '../../components/Authentication/AuthProvider';
import Spinner from '../../components/Spinner';
import HeaderBar from '../../components/HeaderBar';
import { getBasicCounts } from '../../api/dashboardAPI';
import { COLORS } from '../../utils/constants/colors';

const StyledGridCards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
  @media (max-width: 655px) {
    gap: 1rem;
  }
`;

const StyledCountNumber = styled.div`
  font-size: 3rem;
  font-weight: bold;
  color: ${COLORS.darkGray};
`;

const StyledCountDescription = styled.h2`
  color: ${COLORS.darkGray};
`;

const Dashboard = () => {
  const [countNumbers, setCountNumbers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAuth();

  const handleGetChangeCount = useCallback(async () => {
    setIsLoading(true);

    const basicCount = await getBasicCounts(token);

    setCountNumbers({ basicCount });
    setIsLoading(false);
  }, [token]);

  useEffect(() => {
    handleGetChangeCount();
  }, [handleGetChangeCount]);

  return (
    <>
      <Nav />
      <PageWrapper>
        <HeaderBar title='Dashboard' />
        <StyledGridCards>
          <Container>
            <StyledCountNumber>
              {countNumbers?.basicCount?.paired_readers}
            </StyledCountNumber>
            <StyledCountDescription>Paired Readers</StyledCountDescription>
          </Container>
          <Container>
            <StyledCountNumber>
              {countNumbers?.basicCount?.test_results}
            </StyledCountNumber>
            <StyledCountDescription>Test Results</StyledCountDescription>
          </Container>
          <Container>
            <StyledCountNumber>
              {countNumbers?.basicCount?.user_accounts}
            </StyledCountNumber>
            <StyledCountDescription>User Accounts</StyledCountDescription>
          </Container>
        </StyledGridCards>
      </PageWrapper>
      {isLoading && <Spinner />}
    </>
  );
};
export default Dashboard;
