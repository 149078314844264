import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PasswordStrengthBar from 'react-password-strength-bar';
import InputField from '../InputField';
import Button from '../Button';
import Spinner from '../Spinner';
import Alert from '../Alert';
import { useAuth } from '../Authentication/AuthProvider';
import { adminUpdateAnotherUser } from '../../api/usersAPI';
import { deleteToken } from '../../api/authenticationAPI';
import { COLORS } from '../../utils/constants/colors';
import Select from '../Select';

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
`;

const Form = styled.form`
  padding: 0 0.5rem 0 0;
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 1rem;
  border-top: solid 1px ${COLORS.lighterGray};
`;

const ButtonCentered = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content !important;
`;

const StyledPasswordStrengthBar = styled(PasswordStrengthBar)`
  width: 13rem;
  margin-left: 0.5rem;
  height: 1rem;
  transform: translateY(-1rem);
`;

const CenteredField = styled.div`
  width: 12.7rem;
`;

const AlertBox = styled.div`
  margin: 1rem;
`;

const UserDetails = ({
  data,
  isUserLoggedInAdmninistrator,
  setWasUserChanged,
}) => {
  const isUserAdministrator = data?.role === 'administrator';
  const [userDetails, setUserDetails] = useState({});
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordHasChanged, setPasswordHasChanged] = useState(false);
  const [userHasBeenChanged, setUserHasBeenChanged] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEditingUser, setIsEditingUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageSecondLine, setMessageSecondLine] = useState('');
  const initialErrors = {
    token: '',
    currentPassword: '',
    password: '',
    passwordConfirmation: '',
  };
  const [error, setError] = useState(initialErrors);

  const { token } = useAuth();
  const navigate = useNavigate();

  const { username, role, email, firstName, surname } = userDetails;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleEditUser = (e) => {
    e.preventDefault();
    setMessage('');
    setIsEditingUser(true);
    setIsFormValid(true);
  };

  const handleSelectChange = (e) => {
    setUserDetails({ ...userDetails, role: e.target.value });
  };

  const handleSubmitChanges = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await adminUpdateAnotherUser(
      token,
      userDetails,
      passwordHasChanged,
      password,
      currentPassword,
      isUserLoggedInAdmninistrator
    );

    if (response.status === 200) {
      setMessage('User updated successfully.');
      if (isUserLoggedInAdmninistrator && passwordHasChanged) {
        setMessageSecondLine('Redirecting to login page...');
      }
      setUserHasBeenChanged(true);
      setPassword('');
      setPasswordConfirmation('');
      setCurrentPassword('');
      setError(initialErrors);
      setIsEditingUser(false);
      setIsFormValid(false);
      if (!isUserLoggedInAdmninistrator) {
        setWasUserChanged(true);
      }
      if (
        isUserLoggedInAdmninistrator &&
        passwordHasChanged &&
        response.status === 200
      ) {
        setTimeout(() => {
          deleteToken(token);
          navigate('/login');
        }, 5000);
      }
    } else {
      setUserHasBeenChanged(false);
      setError(initialErrors);
      setMessage('Authentication with password failed.');
      setCurrentPassword('');
      if (!isUserLoggedInAdmninistrator) {
        setWasUserChanged(false);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (password === passwordConfirmation) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [password, passwordConfirmation, currentPassword]);

  useEffect(() => {
    if (password.length) {
      setPasswordHasChanged(true);
      setMessage('');
    } else {
      setPasswordHasChanged(false);
    }
  }, [password]);

  useEffect(() => {
    setUserDetails(data);
  }, [data]);

  const handleBlur = (e) => {
    const { name } = e.target;
    if (name === 'new-password') {
      if (!password) {
        setError({ ...error, password: 'Password is required' });
      } else if (passwordStrength < 1) {
        setError({ ...error, password: 'Please enter a strong password' });
      } else {
        setError({ ...error, password: '' });
      }
    }
    if (name === 'new-password-confirm') {
      if (!passwordConfirmation) {
        setError({
          ...error,
          passwordConfirmation: 'Password is required',
        });
      } else if (password !== passwordConfirmation) {
        setError({ ...error, passwordConfirmation: 'Passwords do not match' });
        setPasswordConfirmation('');
      } else {
        setError({ ...error, passwordConfirmation: '' });
      }
    }
    if (name === 'current-password') {
      if (!currentPassword) {
        setError({ ...error, currentPassword: 'Password is required' });
      } else {
        setError({ ...error, currentPassword: '' });
      }
    }
  };

  return (
    <Form onSubmit={isEditingUser ? handleSubmitChanges : handleEditUser}>
      <Rows>
        <Grid>
          <InputField
            label="Username"
            name="username"
            type="text"
            value={username || ''}
            onChange={handleInputChange}
            disabled
          />
          <Select
            label="Role"
            onSelectChange={handleSelectChange}
            userDetails={userDetails}
            isEditingUser={isEditingUser && !isUserLoggedInAdmninistrator}
            value={role}
          />
        </Grid>
        <InputField
          label="Email"
          name="email"
          type="email"
          value={email || ''}
          onChange={handleInputChange}
          disabled
        />
        <Grid>
          <InputField
            label="First Name"
            name="firstName"
            type="text"
            value={firstName || ''}
            onChange={handleInputChange}
            disabled={!isEditingUser}
          />
          <InputField
            label="Surname"
            name="surname"
            type="text"
            value={surname || ''}
            onChange={handleInputChange}
            disabled={!isEditingUser}
          />
        </Grid>

        {!isUserAdministrator ? (
          <Grid>
            <InputField
              label="New password"
              name="new-password"
              placeholder="New Password"
              type="password"
              value={password}
              onChange={(e) => {
                setError({ ...error, password: '' });
                setPassword(e.target.value);
              }}
              onBlur={handleBlur}
              error={error.password}
              disabled={!isEditingUser}
            />

            <InputField
              label="Confirm password"
              name="new-password-confirm"
              placeholder="New Password confirm"
              type="password"
              value={passwordConfirmation}
              onChange={(e) => {
                setError({ ...error, passwordConfirmation: '' });
                setPasswordConfirmation(e.target.value);
              }}
              onBlur={handleBlur}
              error={error.passwordConfirmation}
              disabled={!isEditingUser}
            />
          </Grid>
        ) : isUserLoggedInAdmninistrator ? (
          <Grid>
            <InputField
              label="New password"
              name="new-password"
              placeholder="New Password"
              type="password"
              value={password}
              onChange={(e) => {
                setError({ ...error, password: '' });
                setPassword(e.target.value);
              }}
              onBlur={handleBlur}
              error={error.password}
              disabled={!isEditingUser}
            />

            <InputField
              label="Confirm password"
              name="new-password-confirm"
              placeholder="New Password confirm"
              type="password"
              value={passwordConfirmation}
              onChange={(e) => {
                setError({ ...error, passwordConfirmation: '' });
                setPasswordConfirmation(e.target.value);
              }}
              onBlur={handleBlur}
              error={error.passwordConfirmation}
              disabled={!isEditingUser}
            />
          </Grid>
        ) : null}

        {isEditingUser && (
          <StyledPasswordStrengthBar
            password={password}
            barColors={[
              COLORS.gray,
              COLORS.red,
              COLORS.orange,
              COLORS.green,
              COLORS.green,
              COLORS.green,
            ]}
            onChangeScore={(score) => {
              setPasswordStrength(score > 1);
            }}
          />
        )}

        {isUserLoggedInAdmninistrator ? (
          <CenteredField>
            <InputField
              label="Administrator password"
              name="current-password"
              placeholder="Administrator password"
              type="password"
              value={currentPassword}
              onChange={(e) => {
                setError({ ...error, setcurrentPassword: '' });
                setCurrentPassword(e.target.value);
              }}
              onBlur={handleBlur}
              error={error.currentPassword}
              disabled={!isEditingUser || !passwordHasChanged}
            />
          </CenteredField>
        ) : null}

        {userHasBeenChanged ? (
          <>
            <Alert success>{message}</Alert>
            <Alert success>{messageSecondLine}</Alert>
          </>
        ) : (
          <Alert error>{message}</Alert>
        )}
        {isUserAdministrator && !isUserLoggedInAdmninistrator ? (
          <AlertBox>
            <Alert warning>
              This user is a Administrator and cannot be edited.
            </Alert>
          </AlertBox>
        ) : (
          <ButtonGroup>
            {isEditingUser ? (
              <ButtonCentered type="submit" disabled={!isFormValid}>
                Save Changes
              </ButtonCentered>
            ) : (
              <ButtonCentered type="submit">Edit User</ButtonCentered>
            )}
          </ButtonGroup>
        )}
      </Rows>
      {isLoading && <Spinner />}
    </Form>
  );
};

export default UserDetails;
