import styled from 'styled-components';
import { COLORS } from '../utils/constants/colors';

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${COLORS.white};
  height: 100vh;
  gap: 2rem;
`;

const MainContainer = ({ children }) => {
  return <CenteredContainer>{children}</CenteredContainer>;
};

export default MainContainer;
