import styled from 'styled-components';
import { COLORS } from '../utils/constants/colors';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25rem;
  padding: 2rem;
  background-color: ${COLORS.lighterGray};
  border-radius: 0.5rem;
  gap: 2rem;
`;

const Container = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>;
};
export default Container;
