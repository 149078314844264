// search pattern on string and return value from it
export const findBatchID = (string) => {
  const index = string?.indexOf(`"LotID":`) + 9;
  const end = string?.indexOf(',', index);
  return Number(string?.substring(index, end));
};

export const findVersion = (string) => {
  const index = string?.indexOf(`"Version":`) + 11;
  const end = string?.indexOf('}', index);
  return Number(string?.substring(index, end));
};
