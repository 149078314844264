import styled from 'styled-components';
import { COLORS } from '../utils/constants/colors';

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  padding: 0.5rem;
`;

const Label = styled.label`
  color: ${COLORS.darkGray};
  font-size: 0.8rem;
  font-weight: bold;
`;

const Input = styled.input`
  border: 1px solid ${COLORS.lightGray};
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 94%;
  color: ${COLORS.darkGray};
  background-color: ${({ disabled }) =>
    disabled ? COLORS.lightGray : COLORS.white};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
`;

const ErrorMessage = styled.div`
  color: ${COLORS.orange};
  font-size: 0.8rem;
  margin-left: 0.2rem;
`;

const InputField = ({
  label,
  name,
  type,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  required = false,
  disabled,
  ...rest
}) => {
  return (
    <FormGroup>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Input
        {...rest}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        autoComplete='off'
        disabled={disabled}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </FormGroup>
  );
};

export default InputField;
