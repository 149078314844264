import styled from 'styled-components';
import Logo from '../../assets/images/logo.png';
import MainContainer from '../../layout/MainContainer';
import Container from '../../layout/Container';
import Link from '../../components/Link';

const Image = styled.img`
  height: 100px;
`;

const CenterText = styled.div`
  text-align: center;
`;

const Page404 = () => {
  return (
    <MainContainer>
      <Container>
        <Image src={Logo} alt='logo' />
        <CenterText>
          <h1>404</h1>
          <p>Page not found</p>
        </CenterText>
        <Link variant='contained' to='/'>
          Back to login
        </Link>
      </Container>
    </MainContainer>
  );
};
export default Page404;
