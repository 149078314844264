import styled from 'styled-components';
import { COLORS } from '../utils/constants/colors';

const StyledMessage = styled.p`
  font-weight: bold;
  text-align: center;
`;

const Success = styled(StyledMessage)`
  color: ${COLORS.greenRGBA};
`;

const Warning = styled(StyledMessage)`
  color: ${COLORS.orange};
`;

const Error = styled(StyledMessage)`
  color: ${COLORS.red};
`;

const Alert = ({ children, success, warning, error }) => {
  if (success) {
    return <Success success>{children}</Success>;
  }
  if (warning) {
    return <Warning warning>{children}</Warning>;
  }
  if (error) {
    return <Error error>{children}</Error>;
  }
};

export default Alert;
