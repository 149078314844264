import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from '../utils/constants/colors';

const ContainedLink = styled(Link)`
  margin: 0.5rem;
  background-color: ${COLORS.darkGray};
  color: ${COLORS.white};
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 40%;
  text-align: center;

  &:active {
    background-color: ${COLORS.black};
  }
`;

const CustomLinkText = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${COLORS.darkGray};

  &:hover {
    text-decoration: underline;
  }
`;

const CustomLink = ({ children, variant, fontSize, ...props }) => {
  if (variant === 'contained') {
    return (
      <ContainedLink style={{ fontSize }} {...props}>
        {children}
      </ContainedLink>
    );
  }
  return (
    <CustomLinkText style={{ fontSize }} {...props}>
      {children}
    </CustomLinkText>
  );
};

export default CustomLink;
