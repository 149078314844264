import moment from 'moment';
import axios from './axios';

// READ
export const getAllUsers = async (token) => {
  try {
    const response = await axios.get('/users', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const formattedData = response?.data?.data.map((user) => {
      return {
        ...user,
        createdAt: moment(user.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        updatedAt: moment(user.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
        fullname: `${user.firstName} ${user.surname}`,
        role: user.role.charAt(0).toUpperCase() + user.role.slice(1),
      };
    });
    return formattedData;
  } catch (error) {
    return error.response;
  }
};

export const getUserDetails = async (token, id) => {
  try {
    const response = await axios.get(`/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data?.data;
  } catch (error) {
    return error.response;
  }
};

export const adminUpdateAnotherUser = async (
  token,
  userDetails,
  passwordHasChanged,
  password,
  currentPassword,
  isUserLoggedInAdmninistrator = false
) => {
  const { firstName, surname, role } = userDetails;

  const bodyWithPassword = {
    changes: {
      firstName,
      surname,
      password,
      ...(!isUserLoggedInAdmninistrator && { role }),
    },
    currentPassword,
  };

  const bodyWithNoPassword = {
    changes: {
      firstName,
      surname,
      ...(!isUserLoggedInAdmninistrator && { role }),
    },
  };

  const body =
    passwordHasChanged && isUserLoggedInAdmninistrator
      ? bodyWithPassword
      : bodyWithNoPassword;

  try {
    const response = await axios.patch(
      `/user/${userDetails?.id}`,
      {
        ...body,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (err) {
    return err.response;
  }
};

export const deleteUser = async (
  token,
  id,
  password,
  isSelectedUserAdmin,
  adminUserWasSelected
) => {
  const bodyWithPassword = isSelectedUserAdmin || adminUserWasSelected;
  try {
    if (bodyWithPassword) {
      const response = await axios.delete(`/user/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { password },
      });
      return response;
    } else {
      const response = await axios.delete(`/user/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    }
  } catch (err) {
    return err.response;
  }
};

export const adminCreateUser = async (token, userDetails, password) => {
  try {
    const { firstName, surname, email, role } = userDetails;

    const body = {
      firstName,
      surname,
      email,
      role: role?.replace('-', ' '),
      password,
      lang: 'en-GB',
    };

    const response = await axios.post(
      `/user`,
      {
        ...body,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (err) {
    return err.response;
  }
};
