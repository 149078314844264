import { useState, useEffect } from 'react';
import styled from 'styled-components';
import PasswordStrengthBar from 'react-password-strength-bar';
import InputField from '../InputField';
import Spinner from '../Spinner';
import Alert from '../Alert';
import { useAuth } from '../Authentication/AuthProvider';
import { adminCreateUser } from '../../api/usersAPI';
import { COLORS } from '../../utils/constants/colors';
import Select from '../Select';
import Button from '../Button';

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
`;

const Form = styled.form`
  padding: 0 0.5rem 0 0;
`;

const StyledPasswordStrengthBar = styled(PasswordStrengthBar)`
  width: 13rem;
  margin-left: 0.5rem;
  height: 1rem;
  transform: translateY(-1rem);
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 1rem;
  margin-top: 0.5rem;
  border-top: solid 1px ${COLORS.lighterGray};
`;

const ButtonCentered = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  justify-self: flex-end;
  align-items: center;
  width: fit-content !important;
`;

const NewUser = () => {
  const [userDetails, setUserDetails] = useState({});
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageSecondLine, setMessageSecondLine] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const initialErrors = {
    token: '',
    currentPassword: '',
    password: '',
    passwordConfirmation: '',
  };
  const [error, setError] = useState(initialErrors);

  const { token } = useAuth();

  const { role, email, firstName, surname } = userDetails;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
    setMessage('');
  };

  const handleSelectChange = (e) => {
    setUserDetails({ ...userDetails, role: e.target.value });
  };

  useEffect(() => {
    const passwordMatches =
      password.length && password === passwordConfirmation;

    if (
      firstName?.length &&
      surname?.length &&
      email?.length &&
      passwordMatches
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [firstName, surname, email, password, passwordConfirmation]);

  const handelCreateNewUser = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const response = await adminCreateUser(token, userDetails, password);
    if (response.status === 200) {
      setErrorMessage(false);
      setMessage('User created successfully.');
      setMessageSecondLine('Please confirm the account on its email.');
      setIsFormValid(false);
      setUserDetails({});
      setPassword('');
      setPasswordConfirmation('');
    } else {
      setErrorMessage(true);
      const errorMessage = response?.data?.errors[0]?.code;
      setMessage(
        errorMessage
          ? errorMessage?.replace(
              errorMessage?.charAt(0),
              errorMessage?.charAt(0)?.toUpperCase()
            )
          : ''
      );
    }
    setIsLoading(false);
  };

  return (
    <Form onSubmit={handelCreateNewUser}>
      <Rows>
        <Grid>
          <InputField
            label="First Name"
            name="firstName"
            type="text"
            value={firstName || ''}
            onChange={handleInputChange}
            placeholder="e.g. John"
          />
          <InputField
            label="Surname"
            name="surname"
            type="text"
            value={surname || ''}
            onChange={handleInputChange}
            placeholder="e.g. Kanne"
          />
        </Grid>
        <InputField
          label="Email"
          name="email"
          type="email"
          value={email || ''}
          onChange={handleInputChange}
          placeholder="e.g. johnkanne@gmail.com"
        />
        <Grid>
          <Select
            label="Role"
            onSelectChange={handleSelectChange}
            userDetails={userDetails}
            isEditingUser
            value={role}
            emptySelection
          />
        </Grid>

        <Grid>
          <InputField
            label="New password"
            name="new-password"
            placeholder="New Password"
            type="password"
            value={password}
            onChange={(e) => {
              setError({ ...error, password: '' });
              setPassword(e.target.value);
            }}
            error={error.password}
          />

          <InputField
            label="Confirm password"
            name="new-password-confirm"
            placeholder="New Password confirm"
            type="password"
            value={passwordConfirmation}
            onChange={(e) => {
              setError({ ...error, passwordConfirmation: '' });
              setPasswordConfirmation(e.target.value);
            }}
            error={error.passwordConfirmation}
          />
        </Grid>
        <StyledPasswordStrengthBar
          password={password}
          barColors={[
            COLORS.gray,
            COLORS.red,
            COLORS.orange,
            COLORS.green,
            COLORS.green,
            COLORS.green,
          ]}
        />
        {errorMessage ? (
          <Alert error>{message}</Alert>
        ) : (
          <>
            <Alert success>{message}</Alert>
            <Alert success>{messageSecondLine}</Alert>
          </>
        )}
        <ButtonGroup>
          <ButtonCentered type="submit" disabled={!isFormValid}>
            Create user
          </ButtonCentered>
        </ButtonGroup>
      </Rows>
      {isLoading && <Spinner />}
    </Form>
  );
};

export default NewUser;
