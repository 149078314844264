import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from './IconButton';
import Button from './Button';
import { COLORS } from '../utils/constants/colors';

const StyledHeaderBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 3rem;
  align-items: center;
  margin-bottom: 1rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  color: ${COLORS.darkGray};
`;

const HeaderBar = ({
  title,
  onOpenModal,
  handleDeleteMultiple,
  disableDelete,
}) => {
  const location = useLocation();

  return (
    <>
      <StyledHeaderBar>
        <Title>{title}</Title>
        <ButtonGroup>
          {disableDelete ? (
            <IconButton onClick={handleDeleteMultiple} mR={1}>
              <DeleteOutlineIcon
                style={{ height: '20px', color: `${COLORS.red}` }}
              />
            </IconButton>
          ) : null}
          {location.pathname !== '/users' && location.pathname !== '/' ? (
            <Button
              icon={<CloudUploadOutlinedIcon />}
              small
              onClick={onOpenModal}
            >
              Upload
            </Button>
          ) : null}
          {location.pathname === '/users' ? (
            <Button icon={<PersonAddIcon />} small onClick={onOpenModal}>
              New User
            </Button>
          ) : null}
        </ButtonGroup>
      </StyledHeaderBar>
    </>
  );
};

export default HeaderBar;
