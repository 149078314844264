import axios from './axios';

export const getCSVFile = async (token) => {
  try {
    const response = await axios.get('/download-csv', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};
