import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { saveAs } from 'file-saver';
import Logo from '../assets/images/logo.png';
import Modal from './Modal';
import Spinner from './Spinner';
import { useAuth } from './Authentication/AuthProvider';
import UserDetails from './UserDetails/UserDetails';
import { deleteToken } from '../api/authenticationAPI';
import { getUserDetails } from '../api/usersAPI';
import { getCSVFile } from '../api/exportCSV';
import { COLORS } from '../utils/constants/colors';

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  padding: 0 3rem;
  height: 5rem;
  background-color: ${COLORS.lighterGray};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  @media (max-width: 800px) {
    flex-direction: column;
    height: fit-content;
    padding-bottom: 1rem;
  }
  @media (max-width: 715px) {
    padding: 0 1rem;
    padding-bottom: 1rem;
  }
`;

const StyledMenu = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  @media (max-width: 800px) {
    gap: 0.5rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-top: 0.5rem;
  }
`;

const NavItem = styled.button`
  text-decoration: none;
  color: ${COLORS.darkGray};
  padding: 0.5rem 1rem;
  cursor: pointer;
  border: 1px solid ${COLORS.lightGray};
  border-radius: 0.5rem;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: ${({ pathname, path, backgroundColor }) =>
    backgroundColor
      ? backgroundColor
      : pathname === path
      ? COLORS.white
      : COLORS.lighterGray};
  height: 2.5rem;
  display: flex;
  align-items: center;
`;

const FloatNavItem = styled(NavItem)`
  @media (max-width: 655px) {
    position: absolute;
    right: 0;
    top: 0;
    margin: 1rem 1rem 0;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: ${COLORS.darkGray} !important;
`;

const Img = styled.img`
  height: 80px;
`;

const Nav = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { token, userID } = useAuth();
  const [anchorUserConfig, setAnchorUserConfig] = useState(null);
  const [anchorExport, setAnchorExport] = useState(null);
  const openUserConfig = Boolean(anchorUserConfig);
  const openExport = Boolean(anchorExport);

  const handleExpandUserConfig = (event) => {
    setAnchorUserConfig(event.currentTarget);
  };

  const handleExpandTestResults = (event) => {
    setAnchorExport(event.currentTarget);
  };

  const handleCloseUserConfig = () => {
    setAnchorUserConfig(null);
  };

  const handleCloseExport = () => {
    setAnchorExport(null);
  };

  const handleLogout = () => {
    deleteToken(token);
    navigate('/login');
  };

  const handleShowDetails = async () => {
    setIsLoading(true);
    setAnchorUserConfig(null);
    const response = await getUserDetails(token, userID);
    setUserDetails(response);
    setIsModalOpen(true);
    setIsLoading(false);
  };

  const handleDownloadCSV = async () => {
    const response = await getCSVFile(token);
    saveAs(response.data.data.url, 'data.csv');
  };

  return (
    <>
      <StyledNav>
        <Img src={Logo} alt="logo" />
        <StyledMenu>
          <NavItem onClick={() => navigate('/')} path="/" pathname={pathname}>
            Dashboard
          </NavItem>
          <NavItem
            onClick={() => navigate('/coefficients')}
            path="/coefficients"
            pathname={pathname}
          >
            Coefficients
          </NavItem>
          <NavItem
            onClick={() => navigate('/firmwares')}
            path="/firmwares"
            pathname={pathname}
          >
            Firmware
          </NavItem>
          <NavItem
            onClick={() => navigate('/users')}
            path="/users"
            pathname={pathname}
          >
            Users
          </NavItem>
          <NavItem
            onClick={handleExpandTestResults}
            backgroundColor={COLORS.lighterGray}
          >
            Test Results
          </NavItem>
          <Menu
            id="basic-menu"
            anchorEl={anchorExport}
            open={openExport}
            onClose={handleCloseExport}
            sx={{
              marginTop: '0.2rem',
            }}
          >
            <StyledMenuItem onClick={handleDownloadCSV}>
              Download .csv
              <FileDownloadOutlinedIcon />
            </StyledMenuItem>
          </Menu>
          <FloatNavItem
            onClick={handleExpandUserConfig}
            backgroundColor={COLORS.lighterGray}
          >
            <ManageAccountsIcon sx={{ color: `${COLORS.darkGray}` }} />
          </FloatNavItem>
          <Menu
            id="basic-menu"
            anchorEl={anchorUserConfig}
            open={openUserConfig}
            onClose={handleCloseUserConfig}
            sx={{
              marginTop: '0.2rem',
            }}
          >
            <StyledMenuItem onClick={handleShowDetails}>
              User details
            </StyledMenuItem>
            <StyledMenuItem onClick={handleLogout}>Logout</StyledMenuItem>
          </Menu>
        </StyledMenu>
      </StyledNav>
      <Modal
        title="User details"
        isModalOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        noSaveButton
        noFooter
      >
        <UserDetails data={userDetails} isUserLoggedInAdmninistrator />
      </Modal>
      {isLoading && <Spinner />}
    </>
  );
};

export default Nav;
