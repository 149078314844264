import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

export const RequireAuth = ({ children }) => {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.token && !auth.email) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return children;
};
