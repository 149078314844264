import moment from 'moment';
import axios from './axios';

// READ
export const getAllFirmwares = async (token) => {
  try {
    const response = await axios.get('/firmware_versions', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const formattedData = response?.data?.data.map((firmware) => {
      const splitFileName = firmware.file.split('/');
      const fileName = splitFileName[splitFileName.length - 1];

      return {
        id: firmware.id,
        version: firmware.version,
        file: fileName,
        uploadTime: moment(firmware.upload_time).format('YYYY-MM-DD HH:mm:ss'),
      };
    });

    return formattedData;
  } catch (error) {
    return error.response;
  }
};

//DELETE
export const deleteFirmware = async (id, token) => {
  try {
    await axios.delete(`/firmware/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    return error.response;
  }
};

//UPLOAD
export const uploadFirmware = async (newFirmware, token, notification_text) => {
  const formData = new FormData();

  const splitFileName = newFirmware.name.split('.');
  const MajorRevision = splitFileName[0];
  const MinorRevision = splitFileName[1];
  const BugFixRevision = splitFileName[2];
  const versionNumber = `${MajorRevision}.${MinorRevision}.${BugFixRevision}`;

  formData.append('file', newFirmware);
  formData.append('version', versionNumber);
  formData.append('notification_text', notification_text);

  try {
    const response = await axios.post('/firmware/upload', formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};
