import styled from 'styled-components';
import { COLORS } from '../utils/constants/colors';

const Container = styled.div`
  background-color: ${COLORS.white};
  min-height: 100%;
  padding: 2rem 3rem;

  @media (max-width: 715px) {
    padding: 2rem;
  }
`;

const PageWrapper = ({ children }) => {
  return <Container>{children}</Container>;
};

export default PageWrapper;
