import styled from 'styled-components';
import { COLORS } from '../utils/constants/colors';

const StyledSpinner = styled.div`
  display: inline-block;
  width: 60px;
  height: 60px;

  &:after {
    content: ' ';
    display: block;
    width: 45px;
    height: 45px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid ${COLORS.white};
    border-color: ${COLORS.lighterGray} transparent ${COLORS.lighterGray}
      transparent;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${COLORS.white};
`;

const SpinnerText = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 2rem;
  color: ${COLORS.lighterGray};
  padding-left: 1rem;
`;

const Spinner = () => {
  return (
    <Overlay>
      <StyledSpinner />
      <SpinnerText>Loading...</SpinnerText>
    </Overlay>
  );
};

export default Spinner;
