import axios from './axios';

//DELETE TOKEN
export const deleteToken = (token) => {
  try {
    const response = axios.post(
      '/token/delete',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.removeItem('email', '');
    sessionStorage.removeItem('token', '');

    return response;
  } catch (error) {
    return error.response;
  }
};

// CREATE TOKEN
export const createToken = async (email, password) => {
  try {
    const response = await axios.post('/token/create', {
      email,
      password,
      notification_token: null,
      device_id: null,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};
