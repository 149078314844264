import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../../assets/images/logo.png';
import MainContainer from '../../layout/MainContainer';
import Container from '../../layout/Container';
import InputField from '../../components/InputField';
import Button from '../../components/Button';
import Alert from '../../components/Alert';
import Link from '../../components/Link';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { COLORS } from '../../utils/constants/colors';
import { initiatePasswordRecovery } from '../../api/passwordRecoveryAPI';

const Image = styled.img`
  height: 100px;
`;

const Title = styled.h1`
  color: ${COLORS.darkGray};
  font-size: 1.2rem;
  font-weight: 500;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 15rem;
`;

const RecoveryPassword = () => {
  const [message, setMessage] = useState('');
  const [email, setEmail] = useSessionStorage('email', '');
  const [error, setError] = useState({ email: '' });
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await initiatePasswordRecovery(email);

    if (response?.status) {
      navigate('/reset-password');
    } else {
      setMessage('Email not registered!');
    }
  };

  const handleBlur = () => {
    if (email === '') {
      setError({ email: 'Email is required' });
    } else if (!email.includes('@') || !email.includes('.')) {
      setError({ email: 'Email is invalid' });
    } else {
      setError({ email: '' });
    }
  };

  return (
    <MainContainer>
      <Container>
        <Image src={Logo} alt='logo' />
        <Form onSubmit={handleSubmit} autoComplete='off'>
          <Title>Forgot password ? </Title>
          <InputField
            name='email'
            placeholder='E-mail'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={handleBlur}
            error={error.email}
            autoFocus
          />
          <Button type='submit' disabled={error.email}>
            Reset password
          </Button>
          <Link to='/login' fontSize='0.8rem'>
            Back to login
          </Link>
        </Form>
        {message && <Alert error>{message}</Alert>}
      </Container>
    </MainContainer>
  );
};
export default RecoveryPassword;
